<template>
  <div>
    <!--sub-title -->
    <div id="top-title">
      <h2 id="title"><img src="/images/contents/company/co_2_title.gif" /></h2>
      <div id="sub-copy">
        <p id="title-description">
          <img
            src="/images/contents/small_copy.gif"
            alt="새 시대를 여는 기업"
          />
        </p>
      </div>
    </div>
    <div class="titLine"></div>
    <!-- //sub-title -->
    <!-- location -->
    <div id="Location">
      <ul>
        <li><router-link to="/">HOME</router-link> > Company > CEO 인사말</li>
      </ul>
    </div>

    <div id="company">
      <div class="ceo_con1">
        <div class="ceo_con2">
          <span class="blind"
            >끊임없는 도전과 변화로 고객의 NEEDS를 한발 앞서서 제공하며 사회에
            기여하는 기업이 되겠습니다.</span
          >
          <span class="ceo_txt"
            ><strong>모컨스 대표이사/사장 김용원</strong> 입니다. 저희 회사
            홈페이지를 찾아주셔서 감사 드립니다.<br /><br />
            저희 회사는 2000년 8월 설립 이래 한결 같은 고객 존중의 자세로 정직한
            제품과 감동적인 서비스를 제공하는 것을 목표로 이제
            {{ diff_year }}년을 달려 왔습니다. 앞으로 20년, 30년, 50년, 100년 그
            이상도 고객 존중의 자세를 원칙으로 지키고, 끊임없는 도전과 변화로
            고객의 NEEDS를 한발 앞서서 제공해 나가는 회사로 성장하고 발전하여
            사회에 보답하겠습니다.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CEO',
  data() {
    return {
      diff_year: new Date().getFullYear() - 2000
    }
  }
}
</script>
